/*
 *  Document   : _contactlist.scss
 *  Author     : RedStar Template
 *  Description: This scss file for style related to contact list app
 */
.contact-detail {
  .fa {
    float: left;
    width: 30px;
    font-size: 20px;
    margin-top: 5px;
  }
  span {
    float: left;
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }
  .fa-envelope {
    font-size: 15px;
  }
  .fa-mobile {
    font-size: 25px;
  }
}
.contact-photo {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  img {
    margin: 0 auto;
    width: 130px;
    padding: 3px;
    border: 3px solid rgb(210, 214, 222);
    border-radius: 50% 50% 50% 50%;
  }
}
.contact-usertitle {
  text-align: center;
  margin-top: 5px;
}
.contact-usertitle-name {
  font-size: 20px;
  margin-bottom: 2px;
  font-weight: bold;
  color: #3a405b;
}
.contact-usertitle-job {
  color: #777777;
  font-size: 12px;
  margin-bottom: 5px;
}
.newLabelBtn {
  padding: 20px 0;
  text-align: center;
}
.alert-dismissible .close {
  text-indent: 0;
}
.alert.alert-dismissible {
  color: #ffffff;
}
.contact_list {
  .phone {
    position: relative;
    padding-left: 20px;
    .material-icons {
      position: absolute;
      left: 0;
      font-size: 16px;
      top: 1px;
    }
  }
}
.list-group-unbordered {
  .list-group-item {
    border: 0px;
  }
}
.contact-header {
  display: flex;

  .contact-details-img img {
    position: relative;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0px;
    overflow: hidden;
  }
}

.contact-details-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.contact-details-field {
  display: flex;
  margin-top: 30px;

  .material-icons-two-tone {
    margin: 0 20px 0 10px;
  }

  .contact-detail-info {
    white-space: pre-wrap;
  }

  .color-icon {
    filter: invert(14%) sepia(0%) saturate(5141%) hue-rotate(101deg) brightness(95%) contrast(97%);
  }
}

.contact-form {
  .modalHeader img {
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
}
.contact-details-heading {
  margin: 0px 10px;
}
