@import "assets/scss/_custom-palette";

// @use '@angular/material' as mat;

// @include mat.core();

// // Define custom palettes using the custom module
// $custom-primary: custom.$primary-palette;
// $custom-accent: custom.$accent-palette;
// $custom-warn: custom.$error-palette;

// $custom-theme: mat.define-light-theme(
//     (
//         color: (
//             primary: $custom-primary,
//             accent: $custom-accent,
//             warn: $custom-warn,
//         ),
//         typography: mat.define-typography-config(),
//         density: 0,
//     )
// );

// @include mat.all-component-themes($custom-theme);

// @tailwind base;
// @tailwind components;
// @tailwind utilities;


// @font-face {
//   font-family: 'TH SarabunPSK';
//   src: url('https://fonts.servicemind.asia/other-fonts/th-sarabun-psk/THSarabun.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// /* Define the italic version of the font */
// @font-face {
//   font-family: 'TH SarabunPSK';
//   src: url('https://fonts.servicemind.asia/other-fonts/th-sarabun-psk/THSarabun-Italic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
// }

// /* Define the bold version of the font */
// @font-face {
//   font-family: 'TH SarabunPSK';
//   src: url('https://fonts.servicemind.asia/other-fonts/th-sarabun-psk/THSarabun-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// /* Define the bold and italic version of the font */
// @font-face {
//   font-family: 'TH SarabunPSK';
//   src: url('https://fonts.servicemind.asia/other-fonts/th-sarabun-psk/THSarabun-Bold-Italic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
// }


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  --app-bg-color: #F0F3FB;
  --app-primary-dark: #{map-get($primary-palette, 900)};
  --app-primary: #{map-get($primary-palette, 500)};
  --app-primary-light: #{map-get($primary-palette, 200)};
  --app-accent-dark: #{map-get($accent-palette, 900)};
  --app-accent: #{map-get($accent-palette, 500)};
  --app-accent-light: #{map-get($accent-palette, 200)};
  --app-error-dark: #{map-get($error-palette, 900)};
  --app-error: #{map-get($error-palette, 500)};
  --app-error-light: #{map-get($error-palette, 200)};
  --app-secondary-dark: #{map-get($secondary-palette, 900)};
  --app-secondary: #{map-get($secondary-palette, 500)};
  --app-secondary-light: #{map-get($secondary-palette, 200)};
  --app-success-dark: #{map-get($success-palette, 900)};
  --app-success: #{map-get($success-palette, 500)};
  --app-success-light: #{map-get($success-palette, 200)};
  --app-info-dark: #{map-get($info-palette, 900)};
  --app-info: #{map-get($info-palette, 500)};
  --app-info-light: #{map-get($info-palette, 200)};
  --app-warning-dark: #{map-get($warning-palette, 900)};
  --app-warning: #{map-get($warning-palette, 500)};
  --app-warning-light: #{map-get($warning-palette, 200)};
  --app-danger-dark: #{map-get($danger-palette, 900)};
  --app-danger: #{map-get($danger-palette, 500)};
  --app-danger-light: #{map-get($danger-palette, 200)};
  --app-dark: #{map-get($dark-palette, 500)};
  --app-light: #{map-get($light-palette, 500)};
  --app-light-2: #{map-get($light-palette, 200)};
  --app-light-dark: #{map-get($light-palette, 900)};
  --app-text-color: #{$default-text-color};
  --app-icon-color: #{$default-icon-color};
  --app-sidebar-text-color: #{$default-sidebar-text-color};
  --app-border-width: 1px
}


/* You can add global styles to this file, and also import other style files */


.main-container{
    background-color: var(--app-bg-color);
    padding-top: 76px;
    min-height: 100%;
}

.app-page{
    padding: 10px;
}

.user_profile{
    .user_img {
        margin: 0 0 0 10px;
        border-radius: 50%;
    }
}

.app-data-not-found{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    h5 {
        font-size: 16px !important;
        font-weight: 500 !important;
    }

    p {
        font-size: 14px !important;
    }
}

.app-a {
    font-weight: 500;
    cursor: pointer;
    color: var(--app-primary);
    margin: 0px !important;
}

.app-curser-pointer{
    cursor: pointer !important;
}

.app-mat-menu {
    .mat-mdc-menu-content {
      padding: 0px !important;
  
      .mat-mdc-menu-item {
        color: #757575 !important;
  
        .mat-icon {
          color: #757575 !important;
  
          &.delete:hover {
            color: var(--app-danger) !important;
          }
  
          &.edit:hover {
            color: var(--app-primary) !important;
          }
        }
      }
  
      .active {
        color: var(--app-primary) !important;
  
        .mat-icon {
          color: var(--app-primary) !important;
          margin-right: 5px !important;
        }
      }
    }
}

.app-mat-form-field {
    .mdc-notched-outline__notch {
      border-right: 0px !important;
    }
  }
  
  .app-mat-form-field-suffix {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          margin-left: 10px;
          padding: 10px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  
  .app-mat-form-field-suffix-bordered {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          border-left: 1px solid #9e9e9e;
          margin-left: 10px;
          padding: 10px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  
  .app-w-100 {
    width: 100%;
  }



::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: currentColor;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: currentColor;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
