﻿/*
 *  Document   : _buttons.scss
 *  Author     : RedStar Template
 *  Description: This scss file for button style classes
 */
.example-button-row button,
.example-button-row a {
  margin-right: 8px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-space {
  margin-right: 10px !important;
}
.btn-outline-default {
  border: 1px solid #005cbb;
  color: #005cbb;
  &:hover {
    background-color: #343dff;
    color: rgb(255, 255, 255);
  }
}
